import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"

import { SelectedLanguageContext } from "../contexts/SelectedLanguage"
import Language from "../types/Language"
import { useFindLanguageById, useLanguages } from "../utils/language"
import { getAlternateLocalizedUrl } from "../utils/localizedUrls"

export default function AlternatePagesMeta() {
  const languages = useLanguages()
  const { language } = useContext(SelectedLanguageContext)!
  const location = useLocation()
  const fullLanguageInfo: Language | undefined = useFindLanguageById(language)

  const getIfLanguageIsNotSelectedLanguage = (strapiLanguage: Language) =>
    strapiLanguage.id !== language

  const renderAlternateLink = (strapiLanguage: Language): JSX.Element => {
    if (!fullLanguageInfo) return null
    const href: string = getAlternateLocalizedUrl(
      location.pathname,
      fullLanguageInfo.code,
      strapiLanguage.code
    )
    return (
      <link
        href={href}
        rel="alternate"
        hrefLang={strapiLanguage.code}
        key={strapiLanguage.id}
      />
    )
  }

  const renderAlternateLinks = (): JSX.Element[] => {
    const alternateLanguages: Language[] = languages.filter(
      getIfLanguageIsNotSelectedLanguage
    )
    return alternateLanguages.map(renderAlternateLink)
  }

  const renderedLinks: JSX.Element[] = renderAlternateLinks()

  return <Helmet>{renderedLinks}</Helmet>
}

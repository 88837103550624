import React, { useContext } from "react"
import { SelectedLanguageContext } from "../contexts/SelectedLanguage"
import Language from "../types/Language"
import { useFindLanguageById } from "../utils/language"

import Seo, { Props } from "./seo"

export default function SeoWithLanguage(props: Props) {
  const { language: languageId } = useContext(SelectedLanguageContext)!
  const language: Language | undefined = useFindLanguageById(languageId)
  const languageCode: null | Language["code"] = language ? language.code : null
  return <Seo {...props} lang={languageCode} />
}

import React from "react"
import { withStyles, WithStyles, createStyles, Button } from "@material-ui/core"

const styles = () => {
  const marginX = "1.125rem"
  return createStyles({
    root: {
      padding: 0,
      "& + &": {
        marginLeft: marginX,
      },
      "& svg": {
        width: "100%",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode
  href: string
  ariaLabel: string
}

const StoreButton = ({ classes, children, href, ariaLabel }: Props) => {
  return (
    <Button href={href} classes={classes} aria-label={ariaLabel}>
      {children}
    </Button>
  )
}

export default withStyles(styles)(StoreButton)

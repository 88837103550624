import { withStyles, createStyles, Theme } from "@material-ui/core"

import Wrapper, { wrapperAlignedRightStyles } from "./CardList/Wrapper"
import { show4ColumnsBreakpoint } from "./CardList/Card"

const styles = ({ breakpoints: { up, down } }: Theme) => {
  return createStyles({
    root: {
      [down("md")]: wrapperAlignedRightStyles,
      [up(show4ColumnsBreakpoint)]: {
        overflowX: "hidden",
      },
    },
  })
}

export default withStyles(styles)(Wrapper)

import React from "react"
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core"

import BaseStoreButtons from "../../../../components/StoreButtons"
import AppStore from "../../../../components/StoreButtons/AppStore"
import { flexBreakpoint } from "../../styles"

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      [up(flexBreakpoint)]: {
        margin: 0,
        justifyContent: "flex-start",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

function StoreButtons(props: Props) {
  return (
    <BaseStoreButtons {...props}>
      <AppStore />
    </BaseStoreButtons>
  )
}

export default withStyles(styles)(StoreButtons)

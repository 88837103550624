import React from "react"
import { withStyles, WithStyles, createStyles } from "@material-ui/core"

import PageContainer, { containerMaxWidth } from "../PageContainer"

const emptySpace = `100% - ${containerMaxWidth}`
export const wrapperAlignedRightStyles = {
  paddingRight: 0,
  marginRight: 0,
  maxWidth: `calc(${containerMaxWidth} + ${emptySpace} / 2)`,
}

const styles = () => {
  return createStyles({
    root: {
      display: "flex",
      overflowX: "scroll",
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode
}

const CardListWrapper = ({ classes, children }: Props) => {
  return (
    <PageContainer component="ul" className={classes.root}>
      {children}
    </PageContainer>
  )
}

export default withStyles(styles)(CardListWrapper)

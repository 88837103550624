import React from "react"
import { withStyles, WithStyles, createStyles } from "@material-ui/core"

import BaseLogoAndText, { Props as BaseLogoAndTextProps } from "../LogoAndText"
import Title from "../LogoAndText/Title"

const styles = () => {
  return createStyles({
    root: {
      justifyContent: "center",
    },
    logo: {},
  })
}

interface Props extends WithStyles<typeof styles> {
  title?: BaseLogoAndTextProps["title"]
}

const HeroLogoAndText = (props: Props) => {
  return (
    <BaseLogoAndText
      idPrefix="hero"
      title={<Title component="h1" />}
      {...props}
    />
  )
}

export default withStyles(styles)(HeroLogoAndText)

import React from "react"
import { withStyles, createStyles, Theme, WithStyles } from "@material-ui/core"

import { flexBreakpoint } from "../styles"
import HeroLogoAndText from "../../../components/HeroLogoAndText"
import Title from "../../../components/LogoAndText/Title"
import { useHomeLanguageText } from "../../../utils/language"

const styles = ({ breakpoints: { up } }: Theme) => {
  const upFlexBreakpoint = up(flexBreakpoint)
  const xl = up("xl")
  return createStyles({
    root: {
      marginBottom: "6.5625rem",
      justifyContent: "center",
      [upFlexBreakpoint]: {
        justifyContent: "flex-start",
        marginBottom: "5.875rem",
      },
      [xl]: {
        marginBottom: "5.875rem",
      },
    },
    logo: {
      [upFlexBreakpoint]: {
        width: "34.0625rem",
      },
      [xl]: {
        width: "44.125rem",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const LogoAndText = ({ classes }: Props) => {
  const {
    hero: { seoTitle },
  } = useHomeLanguageText()

  return (
    <HeroLogoAndText
      classes={classes}
      title={<Title seoTitle={seoTitle} component="h1" />}
    />
  )
}

export default withStyles(styles)(LogoAndText)

import React from "react"
import { withStyles, WithStyles, createStyles } from "@material-ui/core"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Card, ImageProps } from "./types"
import { imageBorderRadius } from "./styles"

const styles = () => {
  return createStyles({
    root: {
      width: "100%",
      height: "100%",
      "& img": {
        borderRadius: imageBorderRadius,
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, ImageProps {
  title: Card["title"]
}

const Image = ({ classes, alt, file, title }: Props) => {
  const image = getImage(file)

  return (
    <GatsbyImage
      image={image}
      alt={alt || title}
      className={classes.root}
      objectFit="cover"
    />
  )
}

export default withStyles(styles)(Image)

import { withStyles, createStyles, Theme } from "@material-ui/core"

import Info from "../../../components/Card/Info"

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      [up("lg")]: {
        height: "13rem",
      },
      [up("xl")]: {
        height: "15.375rem",
      },
    },
  })
}

export default withStyles(styles)(Info)

import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

import Body from "./Body"
import Screenshot from "./Screenshot"
import Explanation from "./Explanation"
import { explanationSpacing, flexBreakpoint } from "./styles"
import PageContainer from "../../components/PageContainer"

const styles = ({ breakpoints: { up } }: Theme) => {
  const upMd = up(flexBreakpoint)
  const lg = up("lg")
  const xl = up("xl")
  return createStyles({
    root: {
      marginBottom: "6.4375rem",
      [upMd]: {
        marginTop: "9.1875rem",
        marginBottom: "5.625rem",
      },
      [lg]: {
        marginBottom: "10.75rem",
      },
      [xl]: {
        marginTop: "5.875rem",
        marginBottom: "8.25rem",
      },
    },
    bodyAndScreenshot: {
      [upMd]: {
        display: "flex",
        alignItems: "flex-end",
        marginBottom: explanationSpacing,
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Hero = ({ classes }: Props) => {
  return (
    <section className={classes.root}>
      <PageContainer>
        <div className={classes.bodyAndScreenshot}>
          <Body />
          <Screenshot direction="middle" />
        </div>
        <Explanation />
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(Hero)

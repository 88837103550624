import React from "react"
import { withStyles, WithStyles, createStyles } from "@material-ui/core"

import Image from "./CardImage"
import CardInfo from "./Info"
import { Card as CardInterface } from "./types"

export interface BaseProps extends CardInterface {}

const styles = () => {
  return createStyles({
    root: {
      listStyle: "none",
      position: "relative",
    },
    content: {
      height: "100%",
    },
  })
}

export interface Props extends WithStyles<typeof styles>, BaseProps {
  info?: React.ReactNode
  image?: React.ReactNode
  Component?: React.ElementType<any>
}

const Card = ({
  classes,
  info,
  title,
  alt,
  file,
  description,
  image,
  Component = "div",
}: Props) => {
  const renderedInfo = info || (
    <CardInfo title={title} description={description} />
  )
  const renderedImage = image || <Image file={file} alt={alt} title={title} />

  return (
    <Component className={classes.root}>
      <div className={classes.content}>
        {renderedImage}
        {renderedInfo}
      </div>
    </Component>
  )
}

export default withStyles(styles)(Card)

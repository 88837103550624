import { withStyles, createStyles, Theme } from "@material-ui/core"

import { flexBreakpoint } from "../styles"
import Phone from "../../../components/Phone"

const styles = ({ breakpoints: { up } }: Theme) => {
  const flexBreakpointUp = up(flexBreakpoint)
  return createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "4.625rem",
      alignItems: "flex-end",
      [flexBreakpointUp]: {
        marginBottom: 0,
      },
    },
    svg: {
      width: "14.75rem",
      [flexBreakpointUp]: {
        width: "20.625rem",
      },
    },
  })
}

export default withStyles(styles)(Phone)

import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  Theme,
} from "@material-ui/core"

import { useHomeLanguageText } from "../../utils/language"

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up("md")
  const xl = up("xl")

  const textContainer = {
    display: "flex",
  }

  return createStyles({
    explanationContainer: {
      ...textContainer,
      [md]: {
        textAlign: "center",
      },
      justifyContent: "center",
    },
    explanation: {
      [md]: { maxWidth: "50rem" },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Explanation = ({ classes }: Props) => {
  const {
    hero: { explanation },
  } = useHomeLanguageText()

  return (
    <div>
      <div className={classes.explanationContainer}>
        <Typography className={classes.explanation}>{explanation}</Typography>
      </div>     
    </div>
  )
}

export default withStyles(styles)(Explanation)

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet, HelmetProps } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLanguageText } from "../utils/language"

export interface Props {
  description?: string
  lang?: string
  meta?: HelmetProps["meta"]
  title?: string
}

interface LanguageText {
  description: string
}

interface SiteMetadataLanguageText {
  german: LanguageText
  english: LanguageText
}

function Seo({ description, lang, meta = [], title }: Props) {
  const {
    site,
    openGraph: { publicURL: openGraphUrl },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            german {
              description
            }
            english {
              description
            }
          }
        }
        openGraph: file(absolutePath: { regex: "/open-graph/" }) {
          publicURL
        }
      }
    `
  )

  const { english, german } = site.siteMetadata

  const languageTexts: SiteMetadataLanguageText = {
    english,
    german,
  }

  const {
    description: languageDescription,
  } = useLanguageText<SiteMetadataLanguageText>(languageTexts)

  const metaDescription = description || languageDescription
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `${defaultTitle} | %s` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: "og:image",
          content: openGraphUrl,
        },
        ...meta,
      ]}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo

import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
} from "@material-ui/core"

import { Card } from "../types"
import Header from "./Header"
import { useIsDarkMode } from "../../../utils/colors"
import { imageBorderRadius } from "../styles"

const getBackgroundStyle = (hexColor: string): string => {
  return `transparent linear-gradient(180deg, ${hexColor}00 0%, ${hexColor}99 50%, ${hexColor} 100%) 0% 0% no-repeat padding-box`
}

const styles = () => {
  const padding = "1.5625rem"
  return createStyles({
    root: {
      borderBottomLeftRadius: imageBorderRadius,
      borderBottomRightRadius: imageBorderRadius,
      padding: `0 ${padding} ${padding}`,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      textAlign: "left",
      position: "absolute",
      height: "15.4375rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    dark: {
      background: getBackgroundStyle("#000000"),
    },
    light: {
      background: getBackgroundStyle("#FFFFFF"),
    },
    description: {
      fontSize: "1rem",
      lineHeight: "1.25rem",
    },
  })
}

export interface BaseProps {
  title: Card["title"]
  description: Card["description"]
}

interface Props extends WithStyles<typeof styles>, BaseProps {
  header?: React.ReactNode
}

const CardInfo = ({
  classes,
  title,
  description,
  header = <Header title={title} />,
}: Props) => {
  const isDarkMode: boolean = useIsDarkMode()

  const getClasses = (): string => {
    const colorSchemeClass: string = isDarkMode ? classes.dark : classes.light
    return `${classes.root} ${colorSchemeClass}`
  }

  const className: string = getClasses()

  return (
    <div className={className}>
      {header}
      <Typography className={classes.description}>{description}</Typography>
    </div>
  )
}

export default withStyles(styles)(CardInfo)

import React from "react"
import { withStyles, WithStyles, createStyles } from "@material-ui/core"

import { getMarginX } from "../../utils/styles"

const styles = () => {
  return createStyles({
    root: {
      ...getMarginX("auto"),
      display: "flex",
      justifyContent: "center",
      maxWidth: "21.625rem",
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode
}

const StoreButtons = ({ classes, children }: Props) => {
  return <div className={classes.root}>{children}</div>
}

export default withStyles(styles)(StoreButtons)

import React, { useContext } from "react"
import { withStyles, WithStyles, createStyles } from "@material-ui/core"
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import { ColorScheme, ColorSchemeContext } from "../../contexts/ColorScheme"
import { QueryWithNodes } from "../../types/queries"

const styles = () => {
  return createStyles({
    root: {},
    svg: {
      width: "100%",
    },
  })
}

type Direction = "left" | "middle" | "right"

interface Props extends WithStyles<typeof styles> {
  direction: Direction
}

interface Screenshot extends FileNode {
  name: string
}

interface Query {
  allFile: QueryWithNodes<Screenshot>
}

const Phone = ({ classes, direction }: Props) => {
  const { colorScheme } = useContext(ColorSchemeContext)!
  const {
    allFile: { nodes: screenshots },
  }: Query = useStaticQuery(graphql`
    {
      allFile(filter: { absolutePath: { regex: "/screenshots/" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
          name
        }
      }
    }
  `)

  const getIfImageHasName = (name: string) => {
    return (screenshot: Screenshot): boolean => {
      return screenshot.name === name
    }
  }

  const getImageName = (direction: Direction): string => {
    const colorName: ColorScheme =
      colorScheme === "gray" ? "night" : colorScheme
    return `${colorName}-${direction}`
  }

  const findImageForDirection = (direction: Direction): Screenshot => {
    const name: string = getImageName(direction)
    const screenshot: Screenshot | undefined = screenshots.find(
      getIfImageHasName(name)
    )
    if (screenshot) return screenshot
    throw new Error(
      `Could not find screenshot in images/screenshots with name ${name}`
    )
  }

  const getImageForDirection = (direction: Direction): IGatsbyImageData => {
    const screenshot: Screenshot = findImageForDirection(direction)
    return getImage(screenshot)
  }

  const imageData = getImageForDirection(direction)

  return (
    <div className={classes.root}>
      <GatsbyImage
        image={imageData}
        className={classes.svg}
        alt="App screenshot"
      />
    </div>
  )
}

export default withStyles(styles)(Phone)

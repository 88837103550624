// The Card List card. Automatically sets width and spacing
import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

import BaseCard, {
  BaseProps as BaseCardBaseProps,
  Props as BaseCardProps,
} from "../../Card"
import { CardMarginInfo } from "../../../utils/styles"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"

const getWidth = (
  columns: number,
  margin: string,
  useOffset: boolean
): string => {
  const columnGaps: number = columns - 1
  const totalMargin: string = `${margin} * ${columnGaps}`
  const marginPerColumn: string = `${totalMargin} / ${columns}`
  const cardWidth: string = `(100% / ${columns})`
  const cardOffset: string = useOffset ? `${cardWidth} * 0.2` : `0px`
  return `calc(${cardWidth} - ${marginPerColumn} - ${cardOffset})`
}

const getWidthStyles = (
  columns: number,
  margin: string,
  useOffset: boolean
) => {
  const width = getWidth(columns, margin, useOffset)
  return {
    flexBasis: width,
    width,
  }
}

export interface BaseProps extends CardMarginInfo, BaseCardBaseProps {}

export const getBreakpointStyles = (
  columns: number,
  marginX: string,
  useOffset: boolean = true
) => {
  return {
    ...getWidthStyles(columns, marginX, useOffset),
    "& + &": {
      marginLeft: marginX,
    },
  }
}

export const show4ColumnsBreakpoint: Breakpoint = "tabletXl"

const styles = ({ breakpoints: { up } }: Theme) => {
  const flexBreakpoint = "tabletLg"
  const flexBreakpointUp = up(flexBreakpoint)
  const md = up("md")
  const show4ColumnsUp = up(show4ColumnsBreakpoint)
  const xl = up("xl")

  return createStyles({
    root: {
      listStyle: "none",
      flexShrink: 0,
      ...getBreakpointStyles(1, "2.5rem"),
      [flexBreakpointUp]: {
        ...getBreakpointStyles(2, "2rem"),
      },
      [md]: {
        ...getBreakpointStyles(3, "3.3125rem"),
      },
      [show4ColumnsUp]: {
        ...getBreakpointStyles(4, "1.875rem", false),
      },
      [xl]: {
        ...getBreakpointStyles(4, "2.75rem", false),
      },
    },
  })
}

interface Props
  extends WithStyles<typeof styles>,
    BaseProps,
    Omit<BaseCardProps, "classes" | "component"> {}

const Card = (props: Props) => {
  return <BaseCard Component="li" {...props} />
}

export default withStyles(styles)(Card)

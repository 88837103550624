import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

import CardList from "../../components/CardList"
import { ItemData } from "../../components/Card/types"
import Feature from "../../types/Feature"
import { QueryWithNodes } from "../../types/queries"
import { useItemsWithSelectedLanguage } from "../../utils/language"
import Card from "./Card"
import CardList4ColumnsWrapper from "../../components/CardList4ColumnsWrapper"

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: "6.625rem",
      [up("md")]: {
        marginBottom: "10rem",
      },
      [up("xl")]: {
        marginBottom: "17.375rem",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Features = ({ classes }: Props) => {
  interface Query {
    features: QueryWithNodes<Feature>
  }

  const { features }: Query = useStaticQuery(graphql`
    query Features {
      features: allStrapiHomeFeatures(sort: { order: ASC, fields: order }) {
        nodes {
          title
          description
          strapiId: id
          locale
          image {
            alternativeText
            localFile {
              ...CardImage
            }
          }
        }
      }
    }
  `)

  const featuresForLanguage: Feature[] = useItemsWithSelectedLanguage<Feature>(
    features.nodes
  )

  const getCardData = ({
    title,
    description,
    image: { localFile, alternativeText },
  }: Feature): ItemData => {
    return {
      title,
      description,
      file: localFile,
      alt: alternativeText,
    }
  }

  const cardDataList: ItemData[] = featuresForLanguage.map(getCardData)

  return (
    <section className={classes.root}>
      <CardList
        items={cardDataList}
        Card={Card}
        Wrapper={CardList4ColumnsWrapper}
      />
    </section>
  )
}

export default withStyles(styles)(Features)

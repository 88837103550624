import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
} from "@material-ui/core"

import { Card } from "../types"

const styles = () => {
  return createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      marginBottom: "0.5rem",
      justifyContent: "space-between",
    },
    title: {
      fontSize: "1.25rem",
      lineHeight: "1.8125rem",
      fontWeight: 400,
    },
  })
}

export interface BaseProps {
  title: Card["title"]
}

interface Props extends WithStyles<typeof styles>, BaseProps {
  right?: React.ReactNode
}

const Header = ({ classes, title, right = null }: Props) => {
  return (
    <div className={classes.root}>
      <Typography variant="h2" component="h3" className={classes.title}>
        {title}
      </Typography>
      {right}
    </div>
  )
}

export default withStyles(styles)(Header)

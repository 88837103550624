import React from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core"

import links from "../../constants/links"
import Logo from "./AppleAppstoreLogo"
import { StoreButtonProps } from "./types"
import BaseStoreButton from "./StoreButton"

const styles = () => {
  return createStyles({
    root: {
      width: "56.25%",
    },
  })
}

interface Props extends WithStyles<typeof styles>, StoreButtonProps {}

function AppStore({ StoreButton = BaseStoreButton, classes }: Props) {
  return (
    <StoreButton
      href={links.appStore}
      ariaLabel="Download on the App Store"
      classes={classes}
    >
      <Logo />
    </StoreButton>
  )
}

export default withStyles(styles)(AppStore)

import React from "react"

import DefaultWrapper from "./Wrapper"
import { ItemData as DefaultItemData } from "../Card/types"
import BaseCard from "./Card"
import { CardMarginInfo } from "../../utils/styles"

export function getCardList<
  ItemData extends DefaultItemData = DefaultItemData
>() {
  type CardProps = ItemData & CardMarginInfo

  interface Props {
    items: ItemData[]
    Card?: React.FunctionComponent<CardProps>
    Wrapper?: typeof DefaultWrapper
  }

  function CardList({
    items,
    Card = (BaseCard as unknown) as Props["Card"],
    Wrapper = DefaultWrapper,
  }: Props) {
    const renderItem = (item: ItemData, index: number): JSX.Element => {
      return (
        <Card {...item} postsAmount={items.length} index={index} key={index} />
      )
    }

    const renderedItems: JSX.Element[] = items.map(renderItem)

    return <Wrapper>{renderedItems}</Wrapper>
  }

  return CardList
}

export default getCardList()

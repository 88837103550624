import React from "react"
import {
  Typography,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from "@material-ui/core"

import StoreButtons from "./StoreButtons"
import { useHomeLanguageText } from "../../../utils/language"
import LogoAndText from "./LogoAndText"
import { flexBreakpoint, innerTextPadding } from "../styles"

const styles = ({ breakpoints: { up } }: Theme) => {
  const flexBreakpointUp = up(flexBreakpoint)
  const lg = up("lg")
  const xl = up("xl")
  return createStyles({
    root: {
      marginBottom: "4.125rem",
      [flexBreakpointUp]: {
        marginBottom: 0,
        marginRight: "2rem",
      },
      [lg]: {
        marginRight: "9.8125rem",
      },
      [xl]: {
        marginRight: "9.5rem",
        paddingBottom: "4.5rem",
      },
    },
    content: {
      [xl]: {
        paddingLeft: innerTextPadding,
      },
    },
    title: {
      marginBottom: "1.0625rem",
    },
    description: {
      marginBottom: "2.125rem",
      [flexBreakpointUp]: {
        maxWidth: "35.375rem",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Body = ({ classes }: Props) => {
  const {
    hero: { title, description },
  } = useHomeLanguageText()
  return (
    <div className={classes.root}>
      <LogoAndText />
      <div className={classes.content}>
        <Typography variant="h1" component="h2" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {description}
        </Typography>
        <StoreButtons />
      </div>
    </div>
  )
}

export default withStyles(styles)(Body)

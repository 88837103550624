import * as React from "react"
import loadable from "@loadable/component"

import Hero from "./Hero"
import AlternatePagesMeta from "../components/AlternatePagesMeta"
import Layout from "../components/Layout"
import SeoWithLanguage from "../components/SeoWithLanguage"
import { useHomeLanguageText } from "../utils/language"
import Features from "./Features"

const TriplePhones = loadable(() => import("./TriplePhones"))
const Testimonials = loadable(() => import("./Testimonials"))
const ForKids = loadable(() => import("./ForKids"))
const PeaceAndRelaxation = loadable(() => import("./PeaceAndRelaxation"))

const Home = () => {
  const { title } = useHomeLanguageText()

  return (
    <Layout>
      <SeoWithLanguage title={title} />
      <AlternatePagesMeta />
      <Hero />
      <PeaceAndRelaxation />
      <Features />
      <ForKids />
      <Testimonials />
      <TriplePhones />
    </Layout>
  )
}

export default Home
